<!--
 * @Author: 候怀烨
 * @Date: 2020-12-03 20:16:01
 * @LastEditTime: 2020-12-22 16:13:15
 * @LastEditors: Please set LastEditors
 * @Description: 添加商品
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\AddingGoods.vue
-->
<template>
  <div>
    <el-button type="primary" @click="dialogVisible = true">添加商品</el-button>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="添加商品"
      :visible.sync="dialogVisible"
      width="50%"
      center
    >
      <div>
        <!-- 条件提交表单 -->
        <el-form :model="form" inline>
          <el-form-item>
            <el-select
              v-model="form.class_id"
              placeholder="请选择品类"
              clearable
              style="width: 120px"
            >
              <el-option
                v-for="(list, indexs) in lei"
                :key="indexs"
                :label="list.class_name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader
              v-model="form.brand_id"
              style="width: 120px"
              :options="pai"
              :props="{
                checkStrictly: true,
                value: 'id',
                label: 'brand_name',
                children: 'children',
              }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.keyword"
              placeholder="商品名称简拼"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="chauxun">查 询</el-button>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table
          ref="tables"
          stripe
          :data="shangpinlist"
          border
          :row-key="getRowKey"
          max-height="350"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :reserve-selection="true"
          ></el-table-column>
          <el-table-column
            v-for="(list, index) in clomd"
            :key="index"
            :prop="list.prop"
            :label="list.label"
            :align="list.align"
            width=""
          >
            <template v-if="list.label == '下单价'" #default="{ row }">
              <el-input v-model="row.sell_price"></el-input>
            </template>
            <template v-else #default="{ row }">
              {{ row[list.prop] }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          layout="prev, pager, next"
          :total="totalCount"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tianjia">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        dialogVisible: false,
        form: {
          class_id: '',
          brand_id: '',
          keyword: '',
          is_close: 0,
          pageNo: 1,
          pageSize: 10,
        },
        totalCount: 0,
        url: {
          peilei: '/baseAdmin/common/goods-class-option',
          pinpai: '/baseAdmin/common/goods-brand-option',
          cahx: '/baseAdmin/common/no-goods-list',
        },
        lei: [],
        pai: [],
        shangpinlist: [],
        clomd: [
          {
            prop: 'goods_name',
            label: '商品名称',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'specs',
            label: '规格',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'goods_unit',
            label: '单位',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'sell_price',
            label: '基础销售',
            align: 'center',
            width: 'auto',
          },
          {
            prop: 'sell_price',
            label: '下单价',
            align: 'center',
            width: 'auto',
          },
        ],
        xuanzhong: [],
      }
    },
    watch: {
      dialogVisible(v) {
        if (!v) {
          this.clearSelectGoods()
        }
      },
    },
    mounted() {
      this.handlerpinlei()
      this.handlerpinpai()
      this.handlerdata()
    },
    methods: {
      getRowKey(row) {
        return row.goods_id + row.unit_id
      },
      //改变选中状态
      select(row) {
        this.$refs.tables.toggleRowSelection(row, false)
      },
      // 返回的商品父组件
      tianjia() {
        this.$emit('addgoos', this.xuanzhong)
        this.dialogVisible = false
      },
      // 分页
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerdata()
      },
      // 多选商品
      handleSelectionChange(val) {
        console.log(val, '')
        this.xuanzhong = val
      },
      // 查询
      chauxun() {
        this.form.pageNo = 1
        this.handlerdata()
      },
      // 商品数据
      handlerdata() {
        postAction(this.url.cahx, this.form)
          .then((res) => {
            console.log(res, 'table成功')
            this.shangpinlist = res.data
            this.totalCount = res.totalCount
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 品牌列表
      handlerpinpai() {
        postAction(this.url.pinpai, {})
          .then((res) => {
            console.log(res, 'pinpai')
            this.pai = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 品类list
      handlerpinlei() {
        postAction(this.url.peilei, {})
          .then((res) => {
            console.log(res, '')
            this.lei = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      clearSelectGoods() {
        console.log('清空选择商品')
        this.$refs.tables.clearSelection()
        this.xuanzhong = []
      },
    },
  }
</script>
